var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Toast',{attrs:{"position":"top-right"}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body p-0 py-4"},[_c('div',{staticClass:"aut-area-exibir"},[_c('div',{staticClass:"menus-container"},[_c('b-tabs',_vm._l((_vm.canais_disponiveis),function(canal){return _c('b-tab',{key:canal.value,staticClass:"p-4",attrs:{"title":canal.text}},[_c('div',{staticClass:"header-menus-container"},[_c('h3',[_vm._v(" Itens do Menu - "),_c('span',{staticStyle:{"color":"#ffa800"}},[_vm._v(_vm._s(canal.text))])]),_c('div',{staticClass:"botoes-container"},[_c('b-button',{attrs:{"variant":"success mr-2 text-uppercase"},on:{"click":_vm.salvarAlteracoes}},[_vm._v(" Salvar Alterações ")])],1)]),_c('draggable',_vm._b({staticClass:"list-group",attrs:{"tag":"ul"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}},model:{value:(_vm.menuItens[canal.value]),callback:function ($$v) {_vm.$set(_vm.menuItens, canal.value, $$v)},expression:"menuItens[canal.value]"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":!_vm.drag ? 'flip-list' : null}},_vm._l((_vm.menuItens[canal.value]),function(item,idx){return _c('li',{key:item.chatbot_menu_item_id
                        ? item.chatbot_menu_item_id
                        : idx,staticClass:"list-group-item",class:(item.cmi_ativo ? 'ativo ' : 'inativo ') +
                      (_vm.editavel == item.chatbot_menu_item_id
                        ? 'editando'
                        : '')},[_c('div',{staticClass:"inner-menu-item"},[_c('div',{staticClass:"flexer-container"},[_c('div',{staticClass:"drag-icon"},[_c('i',{staticClass:"fa-solid fa-ellipsis-vertical"}),_c('i',{staticClass:"fa-solid fa-ellipsis-vertical"})]),_c('div',{staticClass:"coluna-item"},[_c('div',{staticClass:"flexer-container"},[_c('div',{staticClass:"flexer-container"},[_c('div',{staticClass:"coluna-item",staticStyle:{"width":"250px"}},[_c('h6',{staticClass:"titulo"},[_vm._v("Título do Menu")]),_c('div',{staticClass:"coluna-item-titulo"},[_vm._v(" "+_vm._s(idx + 1)+" - "+_vm._s(item.cmi_titulo)+" ")]),(_vm.editavel == item.chatbot_menu_item_id)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.cmi_titulo),expression:"item.cmi_titulo"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(item.cmi_titulo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "cmi_titulo", $event.target.value)}}}):_vm._e()])]),_c('div',{staticClass:"coluna-item",staticStyle:{"width":"250px"},attrs:{"id":_vm.editavel == item.chatbot_menu_item_id
                                  ? 'coluna-fluxo-relacionado'
                                  : ''}},[_c('h6',{staticClass:"titulo"},[_vm._v("Fluxo Relacionado")]),_c('div',{staticClass:"coluna-item-titulo"},[_c('span',[_vm._v(" "+_vm._s(_vm.fluxoDoItem(item.chatbot_fluxo_id))+" ")]),(item.chatbot_fluxo_id)?_c('i',{staticClass:"fas fa-external-link-alt",staticStyle:{"font-size":"10px"},on:{"click":function($event){return _vm.abrirFluxoEmNovaAba(item.chatbot_fluxo_id)}}}):_vm._e()]),(_vm.editavel == item.chatbot_menu_item_id)?_c('b-form-group',{staticClass:"w-100"},[_c('pv-dropdown',{staticClass:"form-control w-100",attrs:{"optionLabel":"text","optionValue":"value","emptyFilterMessage":"Nenhum resultado","filter":true,"options":_vm.fluxoOptions,"placeholder":"Nenhum selecionado"},model:{value:(item.chatbot_fluxo_id),callback:function ($$v) {_vm.$set(item, "chatbot_fluxo_id", $$v)},expression:"item.chatbot_fluxo_id"}})],1):_vm._e()],1)]),_c('div',{staticClass:"flexer-container"},[_c('div',{staticClass:"coluna-item w-100"},[_c('h6',{staticClass:"titulo"},[_vm._v("Descrição")]),(_vm.editavel == item.chatbot_menu_item_id)?_c('div',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(item.cmi_descricao),expression:"item.cmi_descricao"}],staticClass:"form-control",attrs:{"maxlength":72},domProps:{"value":(item.cmi_descricao)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "cmi_descricao", $event.target.value)}}}),_c('p',{class:{
                                    'text-danger':
                                      _vm.check(item.cmi_descricao) == 0
                                  }},[_vm._v(" "+_vm._s(_vm.instruction(item.cmi_descricao))+" ")])]):_c('div',[_vm._v(" "+_vm._s(item.cmi_descricao)+" ")])])]),_c('div',{staticClass:"flexer-container"},[_c('div',{staticClass:"coluna-item w-100"},[_c('h6',{staticClass:"titulo"},[_vm._v("Palavras-Chave")]),(_vm.editavel == item.chatbot_menu_item_id)?_c('Chips',{staticClass:"w-100",attrs:{"separator":","},scopedSlots:_vm._u([{key:"chip",fn:function(slotProps){return [_c('div',[_c('span',[_vm._v(_vm._s(slotProps.value))])])]}}],null,true),model:{value:(item.cmi_palavras_chave),callback:function ($$v) {_vm.$set(item, "cmi_palavras_chave", $$v)},expression:"item.cmi_palavras_chave"}}):_c('div',{staticClass:"badges-container"},[(
                                    !item.cmi_palavras_chave ||
                                    item.cmi_palavras_chave.length == 0
                                  )?_c('b-badge',{staticStyle:{"margin-top":"1px","margin-right":"3px","background":"#b0b3b6 !important"},attrs:{"pill":"","variant":"primary"}},[_vm._v("Nenhuma palavra-chave cadastrada")]):_vm._e(),_vm._l((item.cmi_palavras_chave),function(palavra,idx){return _c('b-badge',{key:palavra + idx,staticStyle:{"margin-top":"1px","margin-right":"3px"},attrs:{"pill":"","variant":"primary"}},[_vm._v(_vm._s(palavra.toLowerCase()))])})],2)],1)])])]),_c('div',{staticClass:"btn-group float-right"},[_c('ToggleButton',{staticClass:"w-100 text-uppercase mr-3",attrs:{"onLabel":"Ativo","offLabel":"Inativo","disabled":_vm.editavel != item.chatbot_menu_item_id},model:{value:(item.cmi_ativo),callback:function ($$v) {_vm.$set(item, "cmi_ativo", $$v)},expression:"item.cmi_ativo"}}),(_vm.editavel == item.chatbot_menu_item_id)?_c('b-button',{staticClass:"btn-success",staticStyle:{"border-radius":"5px 0px 0px 5px"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.editarMenuItem(item)}}},[_vm._v("Alterar")]):_vm._e(),(_vm.editavel == item.chatbot_menu_item_id)?_c('b-button',{staticClass:"btn-danger",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.cancelarEdicao(item, canal.value)}}},[_vm._v("Cancelar")]):_vm._e(),(_vm.editavel != item.chatbot_menu_item_id)?_c('b-button',{attrs:{"type":"button","variant":"secondary","disabled":_vm.modoEdicao},on:{"click":function($event){return _vm.ativarModoEdicao(item)}}},[_c('i',{staticClass:"fas fa-edit"})]):_vm._e(),(_vm.editavel != item.chatbot_menu_item_id)?_c('b-button',{attrs:{"type":"button","variant":"danger","disabled":_vm.modoEdicao},on:{"click":function($event){return _vm.excluirItem(item, canal.value)}}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e()],1)])])}),0)],1),_c('button',{staticClass:"novo-menu-btn",on:{"click":function($event){return _vm.criarNovoMenu(canal.value)}}},[_vm._v(" + Novo Menu ")])],1)}),1)],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }